<template>
    <v-container class="a-container">
        <div :class="mobileCentering" class="display-1 primary-text font-weight-bold mb-2 mt-3">Reports</div>
        <v-divider></v-divider>
        <v-row justify="center">
            <v-col cols="auto">
                <v-list
                    style="background-color: transparent"
                >
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            v-for="(item, index) in reportLinks"
                            :key="index"
                            :to="item.link"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{item.text}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Reports",
        data() {
            return {
                reportLinks: [
                    {text: 'Statements', icon: 'mdi-file-document', link: '/reports/statements'},
                    {text: 'Research', icon: 'mdi-flask-outline', link: '/reports/research'},
                ]
            }
        },
        computed: {
             isMobile(){
                return this.$vuetify.breakpoint.mdAndDown
            },

            mobileCentering(){
                return this.isMobile ? 'text-center' : ''
            },

            mobileFilterCentering(){
                return this.isMobile ? 'center' : null
            },
        }
    }
</script>

<style scoped>

</style>